import React from "react"
import { jsx, Styled } from 'theme-ui'
import { graphql } from 'gatsby'
import { motion  } from "framer-motion"
import Layout from "../components/particles/Layout"

const ProNas = ({ location }) => {
    
    return (   
           
            <Layout location={location}>   
                
                test
                 
            </Layout>
        

      
    )
}

export default ProNas